<template>
  <div class="">
    <order-list type="presale"></order-list>
  </div>
</template>
<script>

import OrderList from "@/components/Order/OrderList";
export default {
  components: {
    OrderList
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
  }
};
</script>
<style scoped>
</style>
